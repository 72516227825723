/**
 * TarifIngress | TarifIngress Frontend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { CreateAttribut4TarifDTO } from '../model/create-attribut4-tarif-dto.model';
// @ts-ignore
import { CreateTarifDTO } from '../model/create-tarif-dto.model';
// @ts-ignore
import { DeleteInfoDTO } from '../model/delete-info-dto.model';
// @ts-ignore
import { ListIdResponse } from '../model/list-id-response.model';
// @ts-ignore
import { ListTarifIdsRequestDto } from '../model/list-tarif-ids-request-dto.model';
// @ts-ignore
import { NullableOfListTarif4TarifattributIdsColumn } from '../model/nullable-of-list-tarif4-tarifattribut-ids-column.model';
// @ts-ignore
import { ObjectCreatedResponse } from '../model/object-created-response.model';
// @ts-ignore
import { PropertyUpdatedResponse } from '../model/property-updated-response.model';
// @ts-ignore
import { SortOrderDirection } from '../model/sort-order-direction.model';
// @ts-ignore
import { TarifEntity } from '../model/tarif-entity.model';
// @ts-ignore
import { TarifFuerDropdownDTO } from '../model/tarif-fuer-dropdown-dto.model';
// @ts-ignore
import { Tarife4TarifattributEntity } from '../model/tarife4-tarifattribut-entity.model';
// @ts-ignore
import { UpdateTarifAttributDTO } from '../model/update-tarif-attribut-dto.model';
// @ts-ignore
import { UpdateTarifBeschreibungDTO } from '../model/update-tarif-beschreibung-dto.model';
// @ts-ignore
import { UpdateTarifDTO } from '../model/update-tarif-dto.model';
// @ts-ignore
import { UpdateTarifFondszuordnungenDTO } from '../model/update-tarif-fondszuordnungen-dto.model';
// @ts-ignore
import { UpdateTarifGueltigkeitDTO } from '../model/update-tarif-gueltigkeit-dto.model';
// @ts-ignore
import { UpdateTarifKuerzelDTO } from '../model/update-tarif-kuerzel-dto.model';
// @ts-ignore
import { UpdateTarifNameDTO } from '../model/update-tarif-name-dto.model';
// @ts-ignore
import { UpdateTarifNamensergaenzungDTO } from '../model/update-tarif-namensergaenzung-dto.model';
// @ts-ignore
import { UpdateTarifNotizDTO } from '../model/update-tarif-notiz-dto.model';
// @ts-ignore
import { UpdateTarifTechnischerNameDTO } from '../model/update-tarif-technischer-name-dto.model';
// @ts-ignore
import { UpdateTarifTippDTO } from '../model/update-tarif-tipp-dto.model';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {
    TarifServiceInterface,
    CreateAttribut4TarifRequestParams,
    CreateTarifRequestParams,
    DeleteInfoTarifRequestParams,
    DeleteTarifRequestParams,
    GetTarifRequestParams,
    GetTarifeRequestParams,
    GetTarife4AttributeRequestParams,
    GetTarifeFuerDropdownRequestParams,
    ListTarifIdsRequestParams,
    UpdateAttribut4TarifRequestParams,
    UpdateTarifRequestParams,
    UpdateTarifBeschreibungRequestParams,
    UpdateTarifFondszuordnungenRequestParams,
    UpdateTarifGueltigkeitRequestParams,
    UpdateTarifInfoRequestParams,
    UpdateTarifKuerzelRequestParams,
    UpdateTarifNameRequestParams,
    UpdateTarifNamensergaenzungRequestParams,
    UpdateTarifNotizRequestParams,
    UpdateTarifTechnischerNameRequestParams,
    UpdateTarifTippRequestParams
} from './tarif.serviceInterface';



@Injectable({
  providedIn: 'root'
})
export class TarifService implements TarifServiceInterface {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
            if (firstBasePath != undefined) {
                basePath = firstBasePath;
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-tarif
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAttribut4Tarif(requestParameters?: CreateAttribut4TarifRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ObjectCreatedResponse>;
    public createAttribut4Tarif(requestParameters?: CreateAttribut4TarifRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ObjectCreatedResponse>>;
    public createAttribut4Tarif(requestParameters?: CreateAttribut4TarifRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ObjectCreatedResponse>>;
    public createAttribut4Tarif(requestParameters?: CreateAttribut4TarifRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const tarifId = requestParameters?.tarifId;
        if (tarifId === null || tarifId === undefined) {
            throw new Error('Required parameter tarifId was null or undefined when calling createAttribut4Tarif.');
        }
        const createAttribut4TarifDTO = requestParameters?.createAttribut4TarifDTO;
        if (createAttribut4TarifDTO === null || createAttribut4TarifDTO === undefined) {
            throw new Error('Required parameter createAttribut4TarifDTO was null or undefined when calling createAttribut4Tarif.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/create-tarif/${this.configuration.encodeParam({name: "tarifId", value: tarifId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/attribut`;
        return this.httpClient.request<ObjectCreatedResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: createAttribut4TarifDTO,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-tarif
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTarif(requestParameters?: CreateTarifRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ObjectCreatedResponse>;
    public createTarif(requestParameters?: CreateTarifRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ObjectCreatedResponse>>;
    public createTarif(requestParameters?: CreateTarifRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ObjectCreatedResponse>>;
    public createTarif(requestParameters?: CreateTarifRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const createTarifDTO = requestParameters?.createTarifDTO;
        if (createTarifDTO === null || createTarifDTO === undefined) {
            throw new Error('Required parameter createTarifDTO was null or undefined when calling createTarif.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/create-tarif`;
        return this.httpClient.request<ObjectCreatedResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: createTarifDTO,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-read-tarif
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteInfoTarif(requestParameters?: DeleteInfoTarifRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<DeleteInfoDTO>>;
    public deleteInfoTarif(requestParameters?: DeleteInfoTarifRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<DeleteInfoDTO>>>;
    public deleteInfoTarif(requestParameters?: DeleteInfoTarifRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<DeleteInfoDTO>>>;
    public deleteInfoTarif(requestParameters?: DeleteInfoTarifRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const tarifId = requestParameters?.tarifId;
        if (tarifId === null || tarifId === undefined) {
            throw new Error('Required parameter tarifId was null or undefined when calling deleteInfoTarif.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling deleteInfoTarif.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/delete-info-tarif/${this.configuration.encodeParam({name: "tarifId", value: tarifId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<Array<DeleteInfoDTO>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-tarif
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTarif(requestParameters?: DeleteTarifRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PropertyUpdatedResponse>;
    public deleteTarif(requestParameters?: DeleteTarifRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PropertyUpdatedResponse>>;
    public deleteTarif(requestParameters?: DeleteTarifRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PropertyUpdatedResponse>>;
    public deleteTarif(requestParameters?: DeleteTarifRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const tarifId = requestParameters?.tarifId;
        if (tarifId === null || tarifId === undefined) {
            throw new Error('Required parameter tarifId was null or undefined when calling deleteTarif.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling deleteTarif.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/delete-tarif/${this.configuration.encodeParam({name: "tarifId", value: tarifId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<PropertyUpdatedResponse>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-read-tarif
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTarif(requestParameters?: GetTarifRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<TarifEntity>;
    public getTarif(requestParameters?: GetTarifRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<TarifEntity>>;
    public getTarif(requestParameters?: GetTarifRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<TarifEntity>>;
    public getTarif(requestParameters?: GetTarifRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const tarifId = requestParameters?.tarifId;
        if (tarifId === null || tarifId === undefined) {
            throw new Error('Required parameter tarifId was null or undefined when calling getTarif.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/get-tarif/${this.configuration.encodeParam({name: "tarifId", value: tarifId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<TarifEntity>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-read-tarif
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTarife(requestParameters?: GetTarifeRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<{ [key: string]: TarifEntity; }>;
    public getTarife(requestParameters?: GetTarifeRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<{ [key: string]: TarifEntity; }>>;
    public getTarife(requestParameters?: GetTarifeRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<{ [key: string]: TarifEntity; }>>;
    public getTarife(requestParameters?: GetTarifeRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const requestBody = requestParameters?.requestBody;
        if (requestBody === null || requestBody === undefined) {
            throw new Error('Required parameter requestBody was null or undefined when calling getTarife.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/get-tarife`;
        return this.httpClient.request<{ [key: string]: TarifEntity; }>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: requestBody,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-read-tarif
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTarife4Attribute(requestParameters?: GetTarife4AttributeRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Tarife4TarifattributEntity>;
    public getTarife4Attribute(requestParameters?: GetTarife4AttributeRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Tarife4TarifattributEntity>>;
    public getTarife4Attribute(requestParameters?: GetTarife4AttributeRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Tarife4TarifattributEntity>>;
    public getTarife4Attribute(requestParameters?: GetTarife4AttributeRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const sparte = requestParameters?.sparte;
        if (sparte === null || sparte === undefined) {
            throw new Error('Required parameter sparte was null or undefined when calling getTarife4Attribute.');
        }
        const filterId = requestParameters?.filterId;
        if (filterId === null || filterId === undefined) {
            throw new Error('Required parameter filterId was null or undefined when calling getTarife4Attribute.');
        }
        const filterColumn = requestParameters?.filterColumn;
        const filterValue = requestParameters?.filterValue;
        const orderBy = requestParameters?.orderBy;
        const orderDirection = requestParameters?.orderDirection;
        const limit = requestParameters?.limit;
        const offset = requestParameters?.offset;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (filterId !== undefined && filterId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterId, 'filterId');
        }
        if (filterColumn !== undefined && filterColumn !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterColumn, 'filterColumn');
        }
        if (filterValue !== undefined && filterValue !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterValue, 'filterValue');
        }
        if (orderBy !== undefined && orderBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderBy, 'orderBy');
        }
        if (orderDirection !== undefined && orderDirection !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderDirection, 'orderDirection');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }
        if (offset !== undefined && offset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>offset, 'offset');
        }

        let localVarHeaders = this.defaultHeaders;
        if (sparte !== undefined && sparte !== null) {
            localVarHeaders = localVarHeaders.set('sparte', String(sparte));
        }

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/get-tarife-4-tarifattribut`;
        return this.httpClient.request<Tarife4TarifattributEntity>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-read-tarif
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTarifeFuerDropdown(requestParameters?: GetTarifeFuerDropdownRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<TarifFuerDropdownDTO>>;
    public getTarifeFuerDropdown(requestParameters?: GetTarifeFuerDropdownRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<TarifFuerDropdownDTO>>>;
    public getTarifeFuerDropdown(requestParameters?: GetTarifeFuerDropdownRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<TarifFuerDropdownDTO>>>;
    public getTarifeFuerDropdown(requestParameters?: GetTarifeFuerDropdownRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const versichererId = requestParameters?.versichererId;
        if (versichererId === null || versichererId === undefined) {
            throw new Error('Required parameter versichererId was null or undefined when calling getTarifeFuerDropdown.');
        }
        const sparten = requestParameters?.sparten;
        if (sparten === null || sparten === undefined) {
            throw new Error('Required parameter sparten was null or undefined when calling getTarifeFuerDropdown.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (sparten) {
            sparten.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'sparten');
            })
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/tarife/versicherer/${this.configuration.encodeParam({name: "versichererId", value: versichererId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<Array<TarifFuerDropdownDTO>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-read-tarif
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listTarifIds(requestParameters?: ListTarifIdsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ListIdResponse>;
    public listTarifIds(requestParameters?: ListTarifIdsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ListIdResponse>>;
    public listTarifIds(requestParameters?: ListTarifIdsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ListIdResponse>>;
    public listTarifIds(requestParameters?: ListTarifIdsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const sparte = requestParameters?.sparte;
        if (sparte === null || sparte === undefined) {
            throw new Error('Required parameter sparte was null or undefined when calling listTarifIds.');
        }
        const listTarifIdsRequestDto = requestParameters?.listTarifIdsRequestDto;
        if (listTarifIdsRequestDto === null || listTarifIdsRequestDto === undefined) {
            throw new Error('Required parameter listTarifIdsRequestDto was null or undefined when calling listTarifIds.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (sparte !== undefined && sparte !== null) {
            localVarHeaders = localVarHeaders.set('sparte', String(sparte));
        }

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/list-tarif-ids`;
        return this.httpClient.request<ListIdResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: listTarifIdsRequestDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-tarif
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAttribut4Tarif(requestParameters?: UpdateAttribut4TarifRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PropertyUpdatedResponse>;
    public updateAttribut4Tarif(requestParameters?: UpdateAttribut4TarifRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PropertyUpdatedResponse>>;
    public updateAttribut4Tarif(requestParameters?: UpdateAttribut4TarifRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PropertyUpdatedResponse>>;
    public updateAttribut4Tarif(requestParameters?: UpdateAttribut4TarifRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const tarifId = requestParameters?.tarifId;
        if (tarifId === null || tarifId === undefined) {
            throw new Error('Required parameter tarifId was null or undefined when calling updateAttribut4Tarif.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling updateAttribut4Tarif.');
        }
        const updateTarifAttributDTO = requestParameters?.updateTarifAttributDTO;
        if (updateTarifAttributDTO === null || updateTarifAttributDTO === undefined) {
            throw new Error('Required parameter updateTarifAttributDTO was null or undefined when calling updateAttribut4Tarif.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/update-tarif-attribut/${this.configuration.encodeParam({name: "tarifId", value: tarifId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<PropertyUpdatedResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateTarifAttributDTO,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-tarif
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTarif(requestParameters?: UpdateTarifRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PropertyUpdatedResponse>;
    public updateTarif(requestParameters?: UpdateTarifRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PropertyUpdatedResponse>>;
    public updateTarif(requestParameters?: UpdateTarifRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PropertyUpdatedResponse>>;
    public updateTarif(requestParameters?: UpdateTarifRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const tarifId = requestParameters?.tarifId;
        if (tarifId === null || tarifId === undefined) {
            throw new Error('Required parameter tarifId was null or undefined when calling updateTarif.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling updateTarif.');
        }
        const updateTarifDTO = requestParameters?.updateTarifDTO;
        if (updateTarifDTO === null || updateTarifDTO === undefined) {
            throw new Error('Required parameter updateTarifDTO was null or undefined when calling updateTarif.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/update-tarif/${this.configuration.encodeParam({name: "tarifId", value: tarifId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<PropertyUpdatedResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateTarifDTO,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-tarif
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTarifBeschreibung(requestParameters?: UpdateTarifBeschreibungRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PropertyUpdatedResponse>;
    public updateTarifBeschreibung(requestParameters?: UpdateTarifBeschreibungRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PropertyUpdatedResponse>>;
    public updateTarifBeschreibung(requestParameters?: UpdateTarifBeschreibungRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PropertyUpdatedResponse>>;
    public updateTarifBeschreibung(requestParameters?: UpdateTarifBeschreibungRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const tarifId = requestParameters?.tarifId;
        if (tarifId === null || tarifId === undefined) {
            throw new Error('Required parameter tarifId was null or undefined when calling updateTarifBeschreibung.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling updateTarifBeschreibung.');
        }
        const updateTarifBeschreibungDTO = requestParameters?.updateTarifBeschreibungDTO;
        if (updateTarifBeschreibungDTO === null || updateTarifBeschreibungDTO === undefined) {
            throw new Error('Required parameter updateTarifBeschreibungDTO was null or undefined when calling updateTarifBeschreibung.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/update-tarif-beschreibung/${this.configuration.encodeParam({name: "tarifId", value: tarifId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<PropertyUpdatedResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateTarifBeschreibungDTO,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-tarif
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTarifFondszuordnungen(requestParameters?: UpdateTarifFondszuordnungenRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PropertyUpdatedResponse>;
    public updateTarifFondszuordnungen(requestParameters?: UpdateTarifFondszuordnungenRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PropertyUpdatedResponse>>;
    public updateTarifFondszuordnungen(requestParameters?: UpdateTarifFondszuordnungenRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PropertyUpdatedResponse>>;
    public updateTarifFondszuordnungen(requestParameters?: UpdateTarifFondszuordnungenRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const tarifId = requestParameters?.tarifId;
        if (tarifId === null || tarifId === undefined) {
            throw new Error('Required parameter tarifId was null or undefined when calling updateTarifFondszuordnungen.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling updateTarifFondszuordnungen.');
        }
        const updateTarifFondszuordnungenDTO = requestParameters?.updateTarifFondszuordnungenDTO;
        if (updateTarifFondszuordnungenDTO === null || updateTarifFondszuordnungenDTO === undefined) {
            throw new Error('Required parameter updateTarifFondszuordnungenDTO was null or undefined when calling updateTarifFondszuordnungen.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/update-tarif-fondszuordnungen/${this.configuration.encodeParam({name: "tarifId", value: tarifId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<PropertyUpdatedResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateTarifFondszuordnungenDTO,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-tarif
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTarifGueltigkeit(requestParameters?: UpdateTarifGueltigkeitRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PropertyUpdatedResponse>;
    public updateTarifGueltigkeit(requestParameters?: UpdateTarifGueltigkeitRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PropertyUpdatedResponse>>;
    public updateTarifGueltigkeit(requestParameters?: UpdateTarifGueltigkeitRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PropertyUpdatedResponse>>;
    public updateTarifGueltigkeit(requestParameters?: UpdateTarifGueltigkeitRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const tarifId = requestParameters?.tarifId;
        if (tarifId === null || tarifId === undefined) {
            throw new Error('Required parameter tarifId was null or undefined when calling updateTarifGueltigkeit.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling updateTarifGueltigkeit.');
        }
        const updateTarifGueltigkeitDTO = requestParameters?.updateTarifGueltigkeitDTO;
        if (updateTarifGueltigkeitDTO === null || updateTarifGueltigkeitDTO === undefined) {
            throw new Error('Required parameter updateTarifGueltigkeitDTO was null or undefined when calling updateTarifGueltigkeit.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/update-tarif-gueltigkeit/${this.configuration.encodeParam({name: "tarifId", value: tarifId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<PropertyUpdatedResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateTarifGueltigkeitDTO,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-read-tarif
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTarifInfo(requestParameters?: UpdateTarifInfoRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<DeleteInfoDTO>>;
    public updateTarifInfo(requestParameters?: UpdateTarifInfoRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<DeleteInfoDTO>>>;
    public updateTarifInfo(requestParameters?: UpdateTarifInfoRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<DeleteInfoDTO>>>;
    public updateTarifInfo(requestParameters?: UpdateTarifInfoRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const tarifId = requestParameters?.tarifId;
        if (tarifId === null || tarifId === undefined) {
            throw new Error('Required parameter tarifId was null or undefined when calling updateTarifInfo.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling updateTarifInfo.');
        }
        const updateTarifDTO = requestParameters?.updateTarifDTO;
        if (updateTarifDTO === null || updateTarifDTO === undefined) {
            throw new Error('Required parameter updateTarifDTO was null or undefined when calling updateTarifInfo.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/update-tarif-info/${this.configuration.encodeParam({name: "tarifId", value: tarifId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<Array<DeleteInfoDTO>>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateTarifDTO,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-tarif
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTarifKuerzel(requestParameters?: UpdateTarifKuerzelRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PropertyUpdatedResponse>;
    public updateTarifKuerzel(requestParameters?: UpdateTarifKuerzelRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PropertyUpdatedResponse>>;
    public updateTarifKuerzel(requestParameters?: UpdateTarifKuerzelRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PropertyUpdatedResponse>>;
    public updateTarifKuerzel(requestParameters?: UpdateTarifKuerzelRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const tarifId = requestParameters?.tarifId;
        if (tarifId === null || tarifId === undefined) {
            throw new Error('Required parameter tarifId was null or undefined when calling updateTarifKuerzel.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling updateTarifKuerzel.');
        }
        const updateTarifKuerzelDTO = requestParameters?.updateTarifKuerzelDTO;
        if (updateTarifKuerzelDTO === null || updateTarifKuerzelDTO === undefined) {
            throw new Error('Required parameter updateTarifKuerzelDTO was null or undefined when calling updateTarifKuerzel.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/update-tarif-kuerzel/${this.configuration.encodeParam({name: "tarifId", value: tarifId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<PropertyUpdatedResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateTarifKuerzelDTO,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-tarif
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTarifName(requestParameters?: UpdateTarifNameRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PropertyUpdatedResponse>;
    public updateTarifName(requestParameters?: UpdateTarifNameRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PropertyUpdatedResponse>>;
    public updateTarifName(requestParameters?: UpdateTarifNameRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PropertyUpdatedResponse>>;
    public updateTarifName(requestParameters?: UpdateTarifNameRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const tarifId = requestParameters?.tarifId;
        if (tarifId === null || tarifId === undefined) {
            throw new Error('Required parameter tarifId was null or undefined when calling updateTarifName.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling updateTarifName.');
        }
        const updateTarifNameDTO = requestParameters?.updateTarifNameDTO;
        if (updateTarifNameDTO === null || updateTarifNameDTO === undefined) {
            throw new Error('Required parameter updateTarifNameDTO was null or undefined when calling updateTarifName.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/update-tarif-name/${this.configuration.encodeParam({name: "tarifId", value: tarifId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<PropertyUpdatedResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateTarifNameDTO,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-tarif
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTarifNamensergaenzung(requestParameters?: UpdateTarifNamensergaenzungRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PropertyUpdatedResponse>;
    public updateTarifNamensergaenzung(requestParameters?: UpdateTarifNamensergaenzungRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PropertyUpdatedResponse>>;
    public updateTarifNamensergaenzung(requestParameters?: UpdateTarifNamensergaenzungRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PropertyUpdatedResponse>>;
    public updateTarifNamensergaenzung(requestParameters?: UpdateTarifNamensergaenzungRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const tarifId = requestParameters?.tarifId;
        if (tarifId === null || tarifId === undefined) {
            throw new Error('Required parameter tarifId was null or undefined when calling updateTarifNamensergaenzung.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling updateTarifNamensergaenzung.');
        }
        const updateTarifNamensergaenzungDTO = requestParameters?.updateTarifNamensergaenzungDTO;
        if (updateTarifNamensergaenzungDTO === null || updateTarifNamensergaenzungDTO === undefined) {
            throw new Error('Required parameter updateTarifNamensergaenzungDTO was null or undefined when calling updateTarifNamensergaenzung.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/update-tarif-namensergaenzung/${this.configuration.encodeParam({name: "tarifId", value: tarifId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<PropertyUpdatedResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateTarifNamensergaenzungDTO,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-tarif
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTarifNotiz(requestParameters?: UpdateTarifNotizRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PropertyUpdatedResponse>;
    public updateTarifNotiz(requestParameters?: UpdateTarifNotizRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PropertyUpdatedResponse>>;
    public updateTarifNotiz(requestParameters?: UpdateTarifNotizRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PropertyUpdatedResponse>>;
    public updateTarifNotiz(requestParameters?: UpdateTarifNotizRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const tarifId = requestParameters?.tarifId;
        if (tarifId === null || tarifId === undefined) {
            throw new Error('Required parameter tarifId was null or undefined when calling updateTarifNotiz.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling updateTarifNotiz.');
        }
        const updateTarifNotizDTO = requestParameters?.updateTarifNotizDTO;
        if (updateTarifNotizDTO === null || updateTarifNotizDTO === undefined) {
            throw new Error('Required parameter updateTarifNotizDTO was null or undefined when calling updateTarifNotiz.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/update-tarif-notiz/${this.configuration.encodeParam({name: "tarifId", value: tarifId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<PropertyUpdatedResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateTarifNotizDTO,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-tarif
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTarifTechnischerName(requestParameters?: UpdateTarifTechnischerNameRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PropertyUpdatedResponse>;
    public updateTarifTechnischerName(requestParameters?: UpdateTarifTechnischerNameRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PropertyUpdatedResponse>>;
    public updateTarifTechnischerName(requestParameters?: UpdateTarifTechnischerNameRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PropertyUpdatedResponse>>;
    public updateTarifTechnischerName(requestParameters?: UpdateTarifTechnischerNameRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const tarifId = requestParameters?.tarifId;
        if (tarifId === null || tarifId === undefined) {
            throw new Error('Required parameter tarifId was null or undefined when calling updateTarifTechnischerName.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling updateTarifTechnischerName.');
        }
        const updateTarifTechnischerNameDTO = requestParameters?.updateTarifTechnischerNameDTO;
        if (updateTarifTechnischerNameDTO === null || updateTarifTechnischerNameDTO === undefined) {
            throw new Error('Required parameter updateTarifTechnischerNameDTO was null or undefined when calling updateTarifTechnischerName.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/update-tarif-technischername/${this.configuration.encodeParam({name: "tarifId", value: tarifId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<PropertyUpdatedResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateTarifTechnischerNameDTO,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Requires all of the following roles: cpit-tarifingress-write-tarif
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTarifTipp(requestParameters?: UpdateTarifTippRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PropertyUpdatedResponse>;
    public updateTarifTipp(requestParameters?: UpdateTarifTippRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PropertyUpdatedResponse>>;
    public updateTarifTipp(requestParameters?: UpdateTarifTippRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PropertyUpdatedResponse>>;
    public updateTarifTipp(requestParameters?: UpdateTarifTippRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const tarifId = requestParameters?.tarifId;
        if (tarifId === null || tarifId === undefined) {
            throw new Error('Required parameter tarifId was null or undefined when calling updateTarifTipp.');
        }
        const v = requestParameters?.v;
        if (v === null || v === undefined) {
            throw new Error('Required parameter v was null or undefined when calling updateTarifTipp.');
        }
        const updateTarifTippDTO = requestParameters?.updateTarifTippDTO;
        if (updateTarifTippDTO === null || updateTarifTippDTO === undefined) {
            throw new Error('Required parameter updateTarifTippDTO was null or undefined when calling updateTarifTipp.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (v !== undefined && v !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>v, 'v');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (keycloak) required
        localVarCredential = this.configuration.lookupCredential('keycloak');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/update-tarif-tipp/${this.configuration.encodeParam({name: "tarifId", value: tarifId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<PropertyUpdatedResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateTarifTippDTO,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}
