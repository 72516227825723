/**
 * TarifIngress | TarifIngress Frontend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AttributDataType } from './attribut-data-type.model';
import { AttributTypisierungNumberEntityDefaultValue } from './attribut-typisierung-number-entity-default-value.model';


export interface AttributTypisierungNumberEntity { 
    dataType: AttributDataType;
    defaultValue: AttributTypisierungNumberEntityDefaultValue;
    moreIsBetter?: boolean;
    operator?: AttributTypisierungNumberEntityOperatorEnum;
    benchmark?: number | null;
}
export enum AttributTypisierungNumberEntityOperatorEnum {
    Min = 'Min',
    Max = 'Max',
    Sum = 'Sum'
};



