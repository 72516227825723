/**
 * TarifIngress | TarifIngress Frontend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AttributDataType } from './attribut-data-type.model';


export interface TarifattributBedingungNumberEntity { 
    dataType: AttributDataType;
    min: number | null;
    max: number | null;
    operator: TarifattributBedingungNumberEntityOperatorEnum;
    attributId: string;
}
export enum TarifattributBedingungNumberEntityOperatorEnum {
    MoreOrEqualThan = 'MoreOrEqualThan',
    LessOrEqualThan = 'LessOrEqualThan',
    Range = 'Range'
};



