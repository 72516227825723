/**
 * TarifIngress | TarifIngress Frontend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AttributDataType } from './attribut-data-type.model';
import { AttributTypisierungListDTOElementsInner } from './attribut-typisierung-list-dto-elements-inner.model';


export interface AttributTypisierungListDTO { 
    dataType: AttributDataType;
    defaultValue: Array<number>;
    selektionsverhalten: AttributTypisierungListDTOSelektionsverhaltenEnum;
    elements: Array<AttributTypisierungListDTOElementsInner>;
}
export enum AttributTypisierungListDTOSelektionsverhaltenEnum {
    Any = 'Any',
    All = 'All'
};



